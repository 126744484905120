import React, {FunctionComponent} from 'react';
import {useHistory} from "react-router-dom";

import {ReactComponent as Refresh} from '../../assets/icons/redo.svg';
import {ReactComponent as ArrowRight, ReactComponent as ArrowLeft} from '../../assets/icons/angle-right.svg';

import DilemmaModel from "../../models/Dilemma";


interface OwnProps {
    dilemmas: DilemmaModel[];
    currentDilemmaIndex: number;
    error?: string;
    onSelect: (movement: number) => void;
    onStopProgress: () => void;
}

type Props = OwnProps;

const ControlBox: FunctionComponent<Props> = ({dilemmas, currentDilemmaIndex, error, onSelect, onStopProgress}) => {

    let history = useHistory();

    return (

        <div className="control">
            <div className="control__box">
            {
                dilemmas?.length > 0 &&
                <>
                    <div className="control__grey-bar"
                         style={{width: (currentDilemmaIndex + 1) / dilemmas.length * 100 + '%'}}></div>
                    <div className="control__content">
                        <p>Dilemma {currentDilemmaIndex + 1} van de {dilemmas.length}</p>
                    </div>
                    <div className="control__button-section">
                        <button onClick={onStopProgress} className="control__button-home"><Refresh className="control__redo-icon"/>
                            Startpagina
                        </button>
                        <button onClick={() => onSelect(-1)}
                                className={`control__control-button${currentDilemmaIndex == 0 ? '--disabled' : ''}`}>
                            <ArrowLeft/></button>
                        <button onClick={() => onSelect(1)} className={"control__control-button"}><ArrowRight
                            className="control__arrow-left"/></button>
                    </div>
                    {error &&
                    <p className="control__error">{error}</p>

                    }
                </>
            }
            </div>
        </div>
    );
};

export default ControlBox;
