import React, { FunctionComponent } from 'react';
import Page from '../layout/Page';
import NavBar from "../../components/layout/NavBar";


import { ReactComponent as Family } from '../../assets/icons/Family.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { useHistory } from 'react-router'


interface Props {
    onContinueExperience ?: () => void;
 }

const Home: FunctionComponent<Props> = ({onContinueExperience}) => {
    let history = useHistory();

    const loadNewExperience = () => {
        history.push("/"); 
        history.goBack();
    }

    return (
        <Page>
                          <NavBar />
            <div className="home">
                <div className="home__content">
                    <h2 className="home__header">Armoede Experience</h2>
                    <p className="home__text">In dit volgende onderdeel worden je meerdere keuzes voorgelegd die jij als alleenstaande ouder van twee kinderen tegenkomt in het dagelijks leven. Deze keuzes hebben gevolgen voor je weekbudget en bijvoorbeeld je sociaal netwerk.
                    </p>
                    <p className="home__text">
                        Je krijgt een weekbudget van €80,-  dit is als volgt opgebouwd: €50,- per volwassene en per kind komt er €15,- bij. Het bedrag van €80 is je budget voor levensonderhoud, sociale activiteiten, sparen en onverwachte kosten. De vaste lasten zijn al betaald door de budgetbeheerder.
                    Rondkomen met een budget van €80,- per week kan dilemma’s opleveren. In deze opdracht ga je keuzes maken, waarmee mensen in armoede wekelijks mee te maken krijgen. 
                    </p>
                    {onContinueExperience &&
                        <div className="home__stopped-experience-buttons">
                            <button className="button button--primary" onClick={onContinueExperience}>Voortgang hervatten <ArrowRight className="home__arrow-right-icon" /></button>
                            <button className="button button--secondary" onClick={loadNewExperience} >Begin opnieuw <ArrowRight className="home__arrow-right-icon" /></button>
                        </div>
                    }

                    {!onContinueExperience && 
                        <Link className="button button--primary home__start-button" to="/experience">Start de Armoede Experience <ArrowRight className="home__arrow-right-icon" /></Link>
                    }
                </div>
                <div className="home__image-box">
                    <Family className="home__image-box__family" />
                </div>
            </div>
        </Page>
    );
};

export default Home;
