import React, {ChangeEvent, FormEvent, FunctionComponent, useEffect, useState} from 'react';
import {ReactComponent as Check} from "../../assets/icons/check.svg";
import {ReactComponent as Times} from "../../assets/icons/times.svg";
import {ReactComponent as Edit} from "../../assets/icons/edit.svg";
import ExperienceSetRepository from "../../repos/ExperienceSetRepository";
import ExperienceSet from "../../models/ExperienceSet";

interface OwnProps {
}

type Props = OwnProps;

const AdminBudget: FunctionComponent<Props> = (props) => {

    const experienceSetRepository = new ExperienceSetRepository();

    const [initialBudget, setInitialBudget] = useState(0);
    const [currentBudget, setCurrentBudget] = useState(initialBudget);
    const [editBudget, setEditBudget] = useState(false);

    useEffect(() => {
        experienceSetRepository.find(1).then(experienceSet => {
            setInitialBudget((experienceSet as ExperienceSet).budget);
            setCurrentBudget((experienceSet as ExperienceSet).budget);
        });
    }, []);

    const handleBudgetChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentBudget(+e.target.value);
    }

    const handleBudgetSubmit = async (e: FormEvent) => {
        e.preventDefault();
        await experienceSetRepository.save({id: 1, budget: currentBudget} as ExperienceSet);
        setEditBudget(false);
    }

    return (
        <button className="button button--tertiary admin-budget" type="button">
            {editBudget ?
                <form onSubmit={handleBudgetSubmit}>
                    <div className="form-group">
                        <span className="currency">€</span><label>Startbudget</label>
                        <input type="number" value={currentBudget} onChange={handleBudgetChange}/>
                    </div>
                    <div className="admin-budget-buttons">
                        <Check className="button__right-icon" onClick={handleBudgetSubmit}/>
                        <Times className="button__right-icon"
                               onClick={() => {
                                   setEditBudget(false);
                                   setCurrentBudget(initialBudget)
                               }}/>
                    </div>
                </form>
                :
                <>
                    <span>
                        Startbudget: €{currentBudget}
                    </span>
                    <div className="admin-budget-buttons">
                        <Edit className="button__right-icon" onClick={() => setEditBudget(true)}/>
                    </div>
                </>

            }
        </button>
    );
};

export default AdminBudget;
