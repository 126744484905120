import TokenService from './TokenService';
import {RequestDataType} from "./RestEndpoint";
import {AxiosRequestConfig} from 'axios';
import {ResponseType} from './Requester';

export type AxiosRequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
type AxiosResponseType = 'json' | 'arraybuffer';

class RequestHelper {
    static convertToFormData (object: any): FormData {
        const formData = new FormData()
        Object.keys(object).filter(key => object[key] !== null).forEach(key => {
            let value = object[key]
            if (Array.isArray(value)) {
                for (const item of value) {
                    formData.append(`${key}[]`, item)
                }
                return
            }

            formData.append(key, value)
        })

        return formData
    }

    static getRequestInit(method: AxiosRequestMethod, url: string, responseType: ResponseType, options?: AxiosRequestConfig): AxiosRequestConfig {
        return {
            ...options,

            method,
            url,
            headers: RequestHelper.getHeadersForData(),
            responseType: RequestHelper.getResponseTypeString(responseType),
        };
    }

    static getHeadersForData() {
        const headers: any = {};

        if (TokenService.isAuthenticated()) {
            headers.Authorization = `Bearer ${TokenService.getToken()}`;
        }

        return headers;
    }


    static getResponseTypeString(responseType: ResponseType): AxiosResponseType {
        switch (responseType) {
            case ResponseType.JSON:
                return 'json';
            case ResponseType.ARRAYBUFFER:
                return 'arraybuffer';
        }

        return 'json';
    }
}

export default RequestHelper;
