import React, {FunctionComponent} from 'react';


interface OwnProps {
    onCancel: () => void,
    onConfirm: (param: any) => void,
}

type Props = OwnProps;

const AdminConfirmDelete: FunctionComponent<Props> = ({onCancel, onConfirm}) => {

    return (
        <div className="confirm-delete">
            <div className="confirm-delete__text">
                <p>Wil je dit definitief verwijderen?</p>
            </div>
            <div className="confirm-delete__buttons">
                <button onClick={onCancel} className="button button--secondary">
                    <p>Annuleren</p>
                </button>
                <button onClick={onConfirm} className="button button--primary">
                    <p>Verwijderen</p>
                </button>
            </div>

        </div>
    );
};

export default AdminConfirmDelete;
