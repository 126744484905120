import React, {ChangeEvent, FormEvent, FunctionComponent, useState} from 'react';
import RequestError from "../../models/RequestError";


import DilemmaModel from "../../models/Dilemma";
import {ReactComponent as Check} from "../../assets/icons/check.svg";
import {ReactComponent as Trash} from "../../assets/icons/trash.svg";

import DilemmaRepository from "../../repos/DilemmaRepository";
import ImageUpload from "../utils/ImageUpload";
import RequestHelper from "../../requests/RequestHelper";


interface OwnProps {
    dilemma: DilemmaModel,
    handleFormChange: (event: any) => void,
    formSaved: () => void,
}

type Props = OwnProps;

const AdminDilemmaForm: FunctionComponent<Props> = ({dilemma, handleFormChange, formSaved}) => {

    const [requestError, setRequestError] = useState<RequestError>();
    const [dilemmaForm, setDilemmaForm] = useState<DilemmaModel>(dilemma);
    const dilemmaRepository = new DilemmaRepository();


    const handleChange = (event: ChangeEvent<any>) => {
        const name = event.target.name;
        if (name.includes('option')) {
            const optionNumber = name.substring(6, 7);
            const optionType = name.substring(8);

            const newDilemmaForm = {...dilemmaForm};
            // @ts-ignore
            newDilemmaForm.options[optionNumber - 1][optionType] = event.target.value;
            newDilemmaForm.options[optionNumber - 1].position = optionNumber;
            setDilemmaForm(newDilemmaForm);
        } else {
            setDilemmaForm({
                ...dilemmaForm,
                [event.target.name]: event.target.value
            });
        }
    }

    const onImageChange = (event: ChangeEvent<any>) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];

            const fileReader = new FileReader();
            fileReader.readAsDataURL(img);

            fileReader.onload = (e) => {

                setDilemmaForm({...dilemmaForm,
                    image: fileReader.result,
                    image_url: fileReader.result
                });
            };
         }
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        
        const savedDilemma = await dilemmaRepository.save(dilemmaForm);
        setDilemmaForm(savedDilemma);
        formSaved();
    }

    const handleImageUpload = (image: HTMLImageElement) => {
        setDilemmaForm({...dilemmaForm, image})
    };

    const deleteImage = () => {
        setDilemmaForm({...dilemmaForm,
            image_url: ""
        });

    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off" encType="multiplart/form-data">
            <div className="admin-dilemma-form">
                <div className="admin-dilemma-form__section">
                    <div className={`form-group ${requestError ? 'form-group--error' : ''}`}>
                        <label htmlFor="title">Titel</label>
                        <input type="text"
                               onChange={handleChange}
                               value={dilemmaForm.title ?? ''}
                               name="title"
                               required/>
                    </div>
                    <div className={`form-group ${requestError ? 'form-group--error' : ''}`}>
                        <label htmlFor="description">Omschrijving</label>
                        <textarea id="description"
                                  onChange={handleChange}
                                  value={dilemmaForm.content ?? ''}
                                  name="content"
                                  required/>
                    </div>
                    <div className={`form-group ${requestError ? 'form-group--error' : ''}`}>
                        <label htmlFor="image">Afbeelding</label>
                        <input type="file"
                               onChange={onImageChange}
                               name="image"/>
                    </div>
                    {/* <img src={dilemmaForm.image_url} style={{ maxWidth: "100%" }}/> */}
                    <div className="admin-dilemma-form__image-preview" style={{ backgroundImage: `url("${dilemmaForm.image_url}")` }}>
                        {dilemmaForm.image_url != '' &&
                            <button onClick={deleteImage} className="button button--primary" type="submit">
                                <Trash/>
                            </button>
                        }
    
                    </div>
                    {/* <div className="admin-image"> */}
                        {/* <ImageUpload setImage={handleImageUpload}/> */}
                    {/* </div> */}
                </div>

                <div className="admin-dilemma-form__section">
                    <div className="admin-dilemma-form__shared-inline">
                        <div className={`form-group ${requestError ? 'form-group--error' : ''}`}>
                            <span className="currency">€</span>
                            <label htmlFor="costs_option1">Kosten optie 1</label>
                            <input id="costs_option1" type="number"
                                   onChange={handleChange}
                                   value={typeof dilemmaForm.options[0] !== 'undefined' ? dilemmaForm.options[0].costs : ''}
                                   name="option1.costs"
                                   required/>
                        </div>
                        <div className={`form-group ${requestError ? 'form-group--error' : ''}`}>
                            <span className="currency">€</span>
                            <label htmlFor="costs_option2">Kosten optie 2</label>
                            <input id="costs_option2" type="number"
                                   onChange={handleChange}
                                   name="option2.costs"
                                   value={typeof dilemmaForm.options[1] !== 'undefined' ? dilemmaForm.options[1].costs : ''}
                                   required/>
                        </div>
                    </div>
                    <div className={`form-group ${requestError ? 'form-group--error' : ''}`}>
                        <label htmlFor="description_option1">Omschrijving optie 1</label>
                        <textarea id="description_option1"
                                  onChange={handleChange}
                                  name="option1.content"
                                  value={typeof dilemmaForm.options[0] !== 'undefined' ? dilemmaForm.options[0].content : ''}
                                  required/>
                    </div>
                    <div className={`form-group ${requestError ? 'form-group--error' : ''}`}>
                        <label htmlFor="description_option2">Omschrijving optie 2</label>
                        <textarea id="description_option2"
                                  onChange={handleChange}
                                  name="option2.content"
                                  value={typeof dilemmaForm.options[1] !== 'undefined' ? dilemmaForm.options[1].content : ''}
                                  required/>
                    </div>
                </div>
            </div>

            <div className="admin-dilemma__footer">
                <table>
                    <tbody>
                    <tr>
                        <td>Toegevoegd</td>
                        <td>{dilemmaForm.created_at ?? "-"}</td>
                    </tr>
                    <tr>
                        <td>Laatst gewijzigd</td>
                        <td>{dilemmaForm.updated_at ?? "-"}</td>
                    </tr>
                    </tbody>
                </table>
                <button className="button button--primary" type="submit">
                    <span>Opslaan</span>
                    <Check/>
                </button>
            </div>
        </form>
    );
};

export default AdminDilemmaForm;
