import React, {ChangeEvent, FormEvent, FunctionComponent, useContext, useState} from 'react';
import {AuthContext} from "../../contexts/AuthContext";
import {RouteComponentProps} from 'react-router-dom';
import RequestError from "../../models/RequestError";
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow-right.svg";

interface OwnProps {
}

type Props = OwnProps & RouteComponentProps;

const Login: FunctionComponent<Props> = (props) => {

    const [credentials, setCredentials] = useState(
        {
            email: "",
            password: ""
        }
    );
    const [requestError, setRequestError] = useState<RequestError>();
    const {login} = useContext(AuthContext);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRequestError(undefined);

        setCredentials({...credentials, [e.target.id]: e.target.value})
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const requestError = await login(credentials);
        if (requestError) {
            setRequestError(requestError);
        }

    };


    return (
        <div className="login">
            <form onSubmit={handleSubmit}>
                <h2>INLOGGEN ADMIN</h2>
                <div className={`form-group ${requestError ? 'form-group--error' : ''}`}>
                    <label htmlFor="email">E-mail</label>
                    <input id="email" type="text" value={credentials.email}
                           onChange={handleChange}
                           required/>
                </div>
                <div className={`form-group ${requestError ? 'form-group--error' : ''}`}>
                    <label htmlFor="password">Wachtwoord</label>
                    <input id="password" type="password" value={credentials.password}
                           onChange={handleChange}
                           required/>
                </div>
                <button className="button button--primary" type="submit">
                    <span>Inloggen</span>
                    <ArrowRight className="button__right-icon"/>
                </button>
            </form>
        </div>
    );
};

export default Login;
