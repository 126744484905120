import React, {FunctionComponent, useContext, useState} from 'react';
import Budget from '../guest/Budget';
import DilemmaOption from '../guest/DilemmaOption';

import DilemmaModel from "../../models/Dilemma";

interface Props {
    dilemma: DilemmaModel,
    isSubDilemma: boolean,
    index: number,
    budget: number,
    currentBudget: number,
    onOptionSelect: (dilemma: DilemmaModel, option: any, indexDilemma: number, indexOption: number, isSubDilemma: boolean) => void,
}

const Dilemma: FunctionComponent<Props> = ({dilemma, budget, index, currentBudget, isSubDilemma, onOptionSelect}) => {


    const [options, setOptions] = useState(dilemma ? dilemma.options : []);

    const handleOptionSelect = (option: any, indexOption: number) => {
        onOptionSelect(dilemma, option, index, indexOption, isSubDilemma);
    }

    return (
        
        <div className="dilemma">
            { dilemma &&
                <div className="dilemma__image" style={{ backgroundImage: `url('${dilemma.image_url}')` }}></div>
            }

            <div className="dilemma__content">
                <div className="dilemma__budget">
                <Budget budget={budget} currentBudget={currentBudget}/>
                </div>
                <div className="dilemma__subject">{dilemma ? dilemma.title : ''}</div>
                <div className="dilemma__description">{dilemma ? dilemma.content : ''}</div>
            </div>
            <div className="dilemma_options">
                {dilemma && dilemma.options.map((option: any, index: number) => (
                    <DilemmaOption option={option} currentBudget={currentBudget} index={index} key={option.id} handleOptionSelectDilemma={handleOptionSelect}/>
                ))}
            </div>
        </div>
    );
};

export default Dilemma;
