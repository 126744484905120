import { AxiosRequestConfig } from 'axios';
import Requester, {RequestMethod, ResponseType} from '../requests/Requester';
import RequestHelper from "../requests/RequestHelper";

const requester = new Requester(ResponseType.JSON);

export const get = <T extends unknown>(uri: string, config?: AxiosRequestConfig) => {
    return requester.get<T>(uri, config);
};

export const post = <T extends unknown>(uri: string, data: AxiosRequestConfig) => {
    return requester.post<T>(uri, data);
};

export const put = <T extends unknown>(uri: string, data: AxiosRequestConfig) => {
    // if (data?.data?.image) {
    //     const formData = data.data;
    //     const dataAsFormData = RequestHelper.convertToFormData(formData)
    //     dataAsFormData.append('_method', 'PUT');
    //     return requester.post<T>(uri, {data: dataAsFormData});
    // }
    return requester.put<T>(uri, data);
};

export const destroy = <T extends unknown>(uri: string, config?: AxiosRequestConfig) => {
    return requester.destroy<T>(uri, config);
};

export const request = <T extends unknown>(uri: string, method: RequestMethod, data?: AxiosRequestConfig) => {
    switch(method) {
        case RequestMethod.GET:
            return get<T>(uri, data);
        case RequestMethod.POST:
            return post<T>(uri, data ?? {});
        case RequestMethod.PUT:
            return put<T>(uri, data ?? {});
        case RequestMethod.DELETE:
            return destroy<T>(uri, data);
    }

    return get<T>(uri);
};
