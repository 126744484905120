import React, {FunctionComponent} from 'react';

import Logo from '../../assets/images/logo/logo-footer.svg';


interface OwnProps {

}

type Props = OwnProps;

const Footer: FunctionComponent<Props> = (props) => {

    const getCurrentYear = () => {
        const currentDate = new Date();
        return currentDate.getFullYear();
    }

    return (
        <footer>
            <div className="footer-content">
                <div className="footer-logo">
                    <img src={Logo} alt="footer-logo"/>
                   
                </div>
                <div className="footer-title">
                    <h3>Armoede Experience</h3>
                    <p className="copyright">&copy; {getCurrentYear()} HAN University of Applied Sciences</p>
                </div>

            </div>
        </footer>
    );
};

export default Footer;
