import Repository from './Repository';
import ExperienceSetModel from '../models/ExperienceSet';

class ExperienceSetRepository extends Repository<ExperienceSetModel> {

    public constructor() {
        super(`/experience-sets`);
    }
}
export default ExperienceSetRepository;
