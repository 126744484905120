import React, {FunctionComponent, useContext, useState} from 'react';
import {ReactComponent as ArrowRight } from '../../assets/icons/angle-right.svg';


interface OwnProps {
    option: any,
    index: number,
    currentBudget: number,
    handleOptionSelectDilemma: (option: any, index: number) => void,
}

type Props = OwnProps;

const DilemmaOption: FunctionComponent<Props> = ({option, currentBudget, index, handleOptionSelectDilemma}) => {


    const [selected, setSelected] = useState(false);

    const handleOptionSelect = (option: any) => {
            handleOptionSelectDilemma(option, index);
    }

    return (
        <div className={`option option${option.selected ? '--selected' : currentBudget - option.costs < 0 ? '--disabled' : ''}`} onClick={() => currentBudget - option.costs >= 0 ? handleOptionSelect(option) : 0}><p className="option__description">{option.content}</p><button className="option__button"><ArrowRight className="option__arrow-left"/></button></div>
    );
};

export default DilemmaOption;
