import Repository from './Repository';
import User from '../models/User';
import DilemmaModel from '../models/Dilemma';

import {RequestMethod} from '../requests/Requester';

class DilemmaRepository extends Repository<DilemmaModel> {

    public constructor() {
        super(`/dilemmas`);
    }

    public all() {
        return this.request<DilemmaModel[]>(this.url(), RequestMethod.GET);
    }

}

export default DilemmaRepository;