import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import RequestError from '../models/RequestError';
import RequestHelper, {AxiosRequestMethod} from './RequestHelper';

type HttpResponse<T> = Promise<T>;

export enum ResponseType {
    JSON, ARRAYBUFFER
}

export enum RequestMethod {
    GET, POST, PUT, DELETE
}

class Requester {
    private readonly responseType: ResponseType;
    private readonly axiosInstance: AxiosInstance;

    constructor(requestType: ResponseType, baseUrl?: string) {
        this.responseType = requestType;

        let baseUri: string = '';

        if(baseUrl !== undefined) {
            baseUri = baseUrl;
        } else if(process.env.REACT_APP_API_ENDPOINT) {
            baseUri = process.env.REACT_APP_API_ENDPOINT;
        } else {
            throw new Error('REACT_APP_API_ENDPOINT is not found.');
        }

        this.axiosInstance = axios.create({
            baseURL: baseUri,
        });
    }

    get<T>(url: string, config?: AxiosRequestConfig): HttpResponse<T> {
        return this.request('GET', url, config);
    }

    post<T>(uri: string, config: AxiosRequestConfig): HttpResponse<T> {
        return this.request('POST', uri, config);
    }

    put<T>(uri: string, config: AxiosRequestConfig): HttpResponse<T> {
        return this.request('PUT', uri, config);
    }

    destroy<T>(uri: string, config?: AxiosRequestConfig): HttpResponse<T> {
        return this.request('DELETE', uri, config);
    }

    request<T>(method: AxiosRequestMethod, url: string, options?: AxiosRequestConfig): HttpResponse<T> {
        const requestConfig = RequestHelper.getRequestInit(method, url, this.responseType, options);

        return new Promise((resolve, reject) => {
                return this.axiosInstance.request(requestConfig).then((response) => {
                    const { data } = response;

                    if(data.errors) {
                        reject(
                            new RequestError(response.status, data.message, data.errors)
                        );
                    }

                    resolve(data);
                }).catch((error) => {
                    if(!error.response) return;

                    const response = error.response;
                    const { errors, message } = response.data;

                    reject(
                        new RequestError(response.status, message, errors)
                    );
                });
            }
        );
    }
}

export default Requester;
