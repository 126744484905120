import React, {FunctionComponent, useState} from 'react';
import { AnimatePresence, motion } from "framer-motion"

import DilemmaModel from "../../models/Dilemma";

import AdminDilemmaForm from "./AdminDilemmaForm";
import AdminConfirmDelete from "./AdminConfirmDelete";

import {ReactComponent as Plus} from '../../assets/icons/plus.svg';
import {ReactComponent as Bars} from '../../assets/icons/bars.svg';
import {ReactComponent as Trash} from '../../assets/icons/trash.svg';

import DilemmaOption from '../../models/DilemmaOption';
import Dilemma from '../../models/Dilemma';


interface OwnProps {
    dilemma: DilemmaModel,
    handleHeaderClick: (dilemma: DilemmaModel, key: number) => void;
    deleteDilemma: (dilemma: DilemmaModel, dilemmaIndex: number, dilemmaOption?: number) => void;
    addSubdilemma: (dilemmaIndex: number, optionIndex: number) => void;
    reloadDilemmas: () => void;
    dilemmaIndex: number,
}

type Props = OwnProps;

const AdminDilemma: FunctionComponent<Props> = ({dilemma, dilemmaIndex, handleHeaderClick, deleteDilemma, addSubdilemma, reloadDilemmas}) => {

    const [formData, updateFormData] = useState({});


    const handleFormChange = (event: any) => {

        let property = event.target.name;
        updateFormData({
            ...formData,
            [event.target.name]: event.target.value
          });
  
    }

    const [currentDilemma, setCurrentDilemma] = useState<Dilemma>(dilemma);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [isConfirmingSubdilemma, setIsConfirmingSubdilemma] = useState<boolean>(false);
    const [confirmOptionIndex , setConfirmOptionIndex] = useState<number>();


    const cancelDelete = () => {
        setIsConfirming(false);
        setIsConfirmingSubdilemma(false);
        setConfirmOptionIndex(undefined);
    }

    const confirmDelete = () => {

        deleteDilemma(dilemma, dilemmaIndex, confirmOptionIndex);

        setIsConfirming(false);
        setIsConfirmingSubdilemma(false);
        setConfirmOptionIndex(undefined);
      
    }

    const handleDilemmaDelete = () => {
        setIsConfirming(!isConfirming);
        setIsConfirmingSubdilemma(false); 
    }

    const dilemmaFormSaved = () => {
        handleHeaderClick(dilemma, dilemmaIndex);
        reloadDilemmas();
        setIsSaved(true);
        setTimeout(function(){setIsSaved(false)}, 10000);
    }


    return (
        <div className="admin-dilemma" data-id={dilemma.id}>
                <div className="admin-dilemma__box">
                    <div className="admin-dilemma__header" onClick={() => handleHeaderClick(dilemma, dilemmaIndex)}>
                        <div>
                            <Bars className="admin-dilemma__header-icon"/>
                            <p className="admin-dilemma__header-title">{dilemma.title}</p>
                        </div>
                        <div>
                            {isSaved &&
                            <div className="alert alert--primary">Opgeslagen</div>
                            }
                            <Trash onClick={handleDilemmaDelete} className="admin-dilemma__header-icon"/>
                        </div>
                    </div>
              
                    <AnimatePresence initial={false}>
                        {dilemma.selected && (
                        <motion.section
                            key="content"
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                            open: { opacity: 1, height: "auto"},
                            collapsed: { opacity: 0, height: 0}
                            }}
                            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                        >
                            <div className="admin-dilemma__content">
                                {(isConfirming && !isConfirmingSubdilemma) &&
                                <AdminConfirmDelete onCancel={cancelDelete} onConfirm={confirmDelete}/>
                                }
                                <AdminDilemmaForm dilemma={dilemma} handleFormChange={handleFormChange} formSaved={dilemmaFormSaved}/>
                                {dilemma.options?.length && dilemma.options.map((option: DilemmaOption, index: number) => (
                                    option.sub_dilemma &&
                                    <div key={option.id} style={{ position: "relative" }}>
                                        <div className="admin-dilemma__subdilemma-title">
                                            <p>Vervolgdilemma optie {index + 1} </p>
                                            <Trash onClick={() => {setIsConfirmingSubdilemma(!isConfirmingSubdilemma); setConfirmOptionIndex(index)}}/>
                                        </div>
         
                                        <hr/>
                                        {(isConfirmingSubdilemma && confirmOptionIndex == index) &&
                                            <AdminConfirmDelete onCancel={cancelDelete} onConfirm={confirmDelete}/>
                                        }

                                        <AdminDilemmaForm dilemma={option.sub_dilemma} handleFormChange={handleFormChange}  formSaved={dilemmaFormSaved}/>
                                    </div>
                                ))}

                                <div className="button-group button-group--inline">
                                    {dilemma.options.map((option: DilemmaOption, index: number) => (
                                        !option.sub_dilemma &&
                                        <button className="button button--secondary" type="submit" key={index} onClick={() => addSubdilemma(dilemmaIndex, index)}>
                                            <span>Vervolg optie {index + 1}</span>
                                            <Plus/>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </motion.section>
                        )}
                    </AnimatePresence>
                </div>
        </div>
    );
};

export default AdminDilemma;
