import React, {FunctionComponent} from 'react';


interface OwnProps {
    radius: number,
    stroke: number,
    progress: number,
    budget: number,
    currentBudget: number,
}

type Props = OwnProps;
let currentProgress = 0;
const Dilemma: FunctionComponent<Props> = ({radius, stroke, progress, budget, currentBudget}) => {

    let normalizedRadius = radius - stroke * 2;
    let circumference = normalizedRadius * 2 * Math.PI;

    const strokeDashoffset = circumference - progress / 100 * circumference;

    console.log(strokeDashoffset);

    return (
        <div className="progress-ring">
            <div className="progress-ring__background" style={{ width: normalizedRadius * 2 + (stroke / 2), height: normalizedRadius * 2 + (stroke / 2)}}>
                <svg
                height={(normalizedRadius * 2 + (stroke / 2) )}
                width={(normalizedRadius * 2 + (stroke / 2) )}
                >
                    <circle
                    className="progress-ring__first-ring"
                    strokeWidth={ stroke }
                    strokeDasharray={ circumference + ' ' + circumference }
                    style={ { strokeDashoffset } }
                    r={ normalizedRadius - (stroke / 2) }
                    cx={ (normalizedRadius * 2 + (stroke / 2)) / 2}
                    cy={ (normalizedRadius * 2 + (stroke / 2)) / 2}
                    />
                    <circle
                    className="progress-ring__second-ring"
                    fill="white"
                    r={ normalizedRadius - stroke }
                    cx={ (normalizedRadius * 2 + (stroke / 2)) / 2 }
                    cy={ (normalizedRadius * 2 + (stroke / 2)) / 2}
                    />
                    
                </svg>
            </div>
            <div className="progress-ring__content">
            <p>Overgebleven budget 
             
            </p>
            <p>   &euro;{currentBudget},- / &euro;{budget},-</p>
            </div>
      </div>
    );

    
};




export default Dilemma;
