import { AxiosRequestConfig } from 'axios';
import { get, post, put, destroy, request } from '../helpers/request';
import Model from '../models/Model';
import { RequestMethod } from '../requests/Requester';
import { RequestDataType } from '../requests/RestEndpoint';

interface ResourceData {
    data?: any;
    message?: string;
}

/**
 * Base class representing a Repository for a model
 */
abstract class Repository<T extends Model> {

    private readonly baseUrl: string;
    private readonly data: any;

    /**
     * Creates a new repository
     *
     * @param {string} baseUrl - The baseUrl that the repository will use to retrieve the resource
     *
     * @param {any | undefined} data - Extra data for the repository to access
     */
    protected constructor(baseUrl: string, data?: any) {
        this.baseUrl = baseUrl;
        this.data = data;
    }

    /**
     * Gets a list of the resource
     *
     * @return {Promise}
     */
    public all(): Promise<T[]> {
        return this.withoutWrapping(get<ResourceData>(this.baseUrl));
    }

    /**
     * Gets a single resource
     *
     * @param {number | number[]} id - The id of the resource
     */
    public async find(id: number | number[]): Promise<T | T[] | null> {
        if(!Array.isArray(id)) {
            return this.withoutWrapping(get<ResourceData>(this.getResourceUrl(id)));
        }

        const models: T[] = [];

        for(const modelId of id) {
            const model = await this.withoutWrapping(get<ResourceData>(this.getResourceUrl(modelId)));

            models.push(model);
        }

        return models;
    }

    /**
     * Creates a new resource or updates an existing one
     *
     * @param model - The model
     *
     * @return {Promise}
     */
    public save(model: T): Promise<T> {
        if(this.resourceHasId(model)) {
            return this.withoutWrapping(
                put<ResourceData>(this.getResourceUrl(model), {
                    data: model
                })
            );
        }

        return this.withoutWrapping(
            post<ResourceData>(this.baseUrl, {
                data: model
            })
        );
    }

    /**
     * Saves or creates new resources
     *
     * @param models - A list of modals
     */
    public async saveAll(models: T[]): Promise<T[]> {
        const savedModals: T[] = [];

        // TODO: make more performant with Promise.all
        for (const model of models) {
            const savedModal= await this.save(model);

            savedModals.push(savedModal);
        }

        return savedModals;
    }

    /**
     * Destroys a single resource
     *
     * @param model
     *
     * @return {Promise}
     */
    public destroy(model: T) {
        if(!this.resourceHasId(model)) {
            throw 'Resource cannot be destroyed!';
        }

        return destroy(this.getResourceUrl(model));
    }

    /**
     * Destroys a list of resources
     *
     * @param models - The list of resources
     *
     * @return {Promise}
     */
    public async destroyAll(models: T[]) {
        for(const model of models) {
            await this.destroy(model);
        }
    }

    /**
     * Returns a value saved in the data object
     *
     * @param key - The key of the value
     *
     * @return {any}
     */
    protected getData(key: string): any {
        return this.data[key];
    }

    /**
     * Executes an HTTP request
     *
     * @param {string} url - The url to make the request to
     *
     * @param {RequestMethod} method - The request method
     *
     * @param {RequestDataType} data - Optional, data to be send with the request
     *
     * @return {Promise}
     */
    protected async request<T extends unknown>(url: string, method: RequestMethod, data?: RequestDataType): Promise<T> {
        return this.requestWithOptions(url, method, { data });
    }

    /**
     * Executes an HTTP request with axios request options
     *
     * @param {string} url - The url to make the request to
     *
     * @param {RequestMethod} method - The request method
     *
     * @param {AxiosRequestConfig} config - Optional, data to be send with the request
     *
     * @return {Promise}
     */
    protected async requestWithOptions<T extends unknown>(url: string, method: RequestMethod, config?: AxiosRequestConfig): Promise<T> {
        return this.withoutWrapping(
            request<ResourceData>(url, method, config)
        );
    }

    /**
     * Creates a url with the base url of the repo at front
     *
     * @param {string} url
     *
     * @return {string}
     */
    protected url(url: string = ''): string {
        return this.baseUrl + url;
    }

    /**
     * Creates an entity url with an id in it
     *
     * @param {string} url
     *
     * @param model
     *
     * @return {string}
     */
    protected eUrl(model: T, url: string): string {
        return this.url(`/${model.id}${url}`);
    }

    /**
     * Creates a resource url with the repo baseUrl and a given model
     *
     * @param model
     *
     * @return {string} - The resource url
     */
    private getResourceUrl(model: T | number): string {
        const id = (typeof model === 'object') ? model.id : model;

        return `${this.baseUrl}/${id}`;
    }

    /**
     * Checks if the given resource(model) has an id
     *
     * @param model
     *
     * @return {boolean}
     */
    private resourceHasId(model: T): boolean {
        return model.id !== undefined;
    }

    /**
     * Returns the result without being wrapped into the 'data' field or null if there is no result
     *
     * @param {Promise<ResourceData>} resource - The resource
     *
     * @return {Promise<any>}
     */
    private async withoutWrapping(resource: Promise<ResourceData>): Promise<any> {
        const result = await resource;

        if(result.data) {
            return result.data;
        }

        if(result.message !== undefined) {
            return null;
        }

        return result;
    }
}

export default Repository;


