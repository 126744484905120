import React, { FunctionComponent } from 'react';

import { AnimatePresence } from 'framer-motion';
import { Switch, useLocation } from 'react-router-dom';
import PublicRoute from "../utils/PublicRoute";
import Home from "../pages/Home";
import Experience from "../pages/Experience";
import ClosingPage from "../pages/ClosingPage";

import NotFoundPage from "../utils/NotFoundPage";
import Login from "../auth/Login";
import PrivateRoute from "../utils/PrivateRoute";
import AdminExperienceSet from "../admin/AdminExperienceSet";

interface Props {}

const Content: FunctionComponent<Props> = () => {
    const location: any = useLocation();

    return (
        <main>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.id}>
                    <PublicRoute isRestricted={false} exact={true} path={'/'} component={Home}/>
                    <PublicRoute isRestricted={false} exact={true} path={'/experience'} component={Experience}/>
                    <PublicRoute isRestricted={false} exact={true} path={'/end'} component={ClosingPage}/>

                    <PublicRoute isRestricted={true} exact={true} path={'/login'} component={Login}/>
                    <PrivateRoute exact={true} path={'/admin'} component={AdminExperienceSet}/>

                    <PublicRoute isRestricted={false} exact={false} component={NotFoundPage}/>
                </Switch>
            </AnimatePresence>
        </main>
    );
};

export default Content;
