import React, {FunctionComponent} from 'react';


interface OwnProps {
    budget: number,
    currentBudget: number,
}

type Props = OwnProps;

const Budget: FunctionComponent<Props> = ({budget, currentBudget}) => {

    return (
        <div className="budget">
            <div className="budget__total">Budget: €{currentBudget},-</div>
            <div className="budget__purple-bar" style={{ width:  (currentBudget / budget) * 100 + "%"}}></div>
        </div>
    );
};

export default Budget;
