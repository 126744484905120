import React, {FunctionComponent, useEffect, useState, useRef} from 'react';
import Page from '../layout/Page';
import NavBar from "../../components/layout/NavBar";

import Sortable from "sortablejs";

import {ReactComponent as Plus} from "../../assets/icons/plus.svg";
import AdminBudget from "./AdminBudget";

import AdminDilemma from "./AdminDilemma";

import DilemmaModel from "../../models/Dilemma";
import DilemmaOptionModel from "../../models/DilemmaOption";
import ExperienceSetModel from "../../models/ExperienceSet";

import ExperienceSetRepository from "./../../repos/ExperienceSetRepository";
import DilemmaRepository from '../../repos/DilemmaRepository';


interface Props {}

const AdminExperienceSet: FunctionComponent<Props> = (props) => {

    const [budget, setBudget] = useState<number>(80);
    const [dilemmas, setDilemmas] = useState<DilemmaModel[]>([]);

    const list: any = useRef(null);

    const experienceSetRepository = new ExperienceSetRepository();
    const dilemmaRepository = new DilemmaRepository();


    useEffect(() => {

        loadDilemmas();

        var sortable = new Sortable(list.current, {
            animation: 150,   
            onEnd: moveDilemma,
        });
   
    }, []);

    const loadDilemmas = () => {
        let experienceSet = 1;

        experienceSetRepository.find(experienceSet).then((experience) => {
            experience = experience as ExperienceSetModel
            setDilemmas(experience.dilemmas);
            setBudget(experience.budget);
        });
    }

    const moveDilemma = (event: any) => {
        const fromIndex: number | undefined = event.oldIndex;
        const toIndex : number | undefined = event.newIndex;

        setDilemmas((dilemmas) => {
            if(fromIndex === undefined || toIndex === undefined) return dilemmas;
            
            let updatingDilemmas: any = [...dilemmas]; 

            let toDilemma = updatingDilemmas[toIndex];
            updatingDilemmas[toIndex].position = fromIndex + 1;
            updatingDilemmas[fromIndex].position = toIndex + 1;
            
            setDilemmas(updatingDilemmas);
        
            dilemmaRepository.save(updatingDilemmas[toIndex]);
            dilemmaRepository.save(updatingDilemmas[fromIndex]);

            return [...dilemmas];
        });
    };
    
    const handleHeaderClick = (dilemma: DilemmaModel, key: number) => {

            dilemmas[key].selected = !dilemmas[key].selected;
            setDilemmas([...dilemmas]);

    }

    const deleteDilemma = (dilemma: DilemmaModel, indexDilemma: number, indexOption?: number) => {

        let updatedDilemmas = [...dilemmas];
        
        if(indexOption != undefined) {
            dilemmaRepository.destroy(dilemma.options[indexOption].sub_dilemma ?? {} as DilemmaModel);
            dilemma.options[indexOption].sub_dilemma = undefined;
            updatedDilemmas[indexDilemma] = dilemma;

        } else {
            let index = dilemmas.indexOf(dilemma);
            updatedDilemmas.splice(indexDilemma, 1);
            dilemmaRepository.destroy(dilemma);

        }

        setDilemmas(updatedDilemmas);
    }

    const createEmptyDilemmaObject = () => {
        let newOptions: DilemmaOptionModel[] = [{} as DilemmaOptionModel, {} as DilemmaOptionModel];
        let newDilemma = {options: newOptions} as DilemmaModel
        return newDilemma
    }

    const createDilemma = () => {

        if(dilemmas[dilemmas.length - 1].id) {
            let newDilemma = createEmptyDilemmaObject();
            newDilemma.selected = true;
            newDilemma.experience_set_id = 1;
            newDilemma.position = Math.max(...dilemmas.map(dilemma => dilemma.position)) + 1;
            const newDilemmas = dilemmas.concat(newDilemma);
           
            setDilemmas(newDilemmas);
        }

    }

    const addSubDilemma = (indexDilemma: number, indexOption: number) => {

        const subDilemma = createEmptyDilemmaObject();
        subDilemma.experience_set_id = 1;
        subDilemma.position = indexOption +1;
        subDilemma.option_id = dilemmas[indexDilemma].options[indexOption].id;

        dilemmas[indexDilemma].options[indexOption].sub_dilemma = subDilemma;

        setDilemmas([...dilemmas]);
    }

    const reloadDilemmas = () => {
        loadDilemmas();
    }

    
    return (
        <Page>
            <NavBar/>
            <div className="admin-experience-set">
                <h1>BEHEER EXPERIENCES</h1>
                <div className="button-group button-group--inline">
                    <button className="button button--primary" onClick={createDilemma} type="submit">
                        <span>Experience toevoegen</span>
                        <Plus className="button__right-icon"/>
                    </button>
                    <AdminBudget/>
                </div>
                <div className="admin-experience-set__accordion" id="dilemma-list" ref={list}>
                    {dilemmas && dilemmas.map((dilemma: DilemmaModel, index: number) => (  
                        <AdminDilemma dilemma={dilemma} key={dilemma.id} dilemmaIndex={index} addSubdilemma={addSubDilemma} reloadDilemmas={reloadDilemmas} deleteDilemma={deleteDilemma} handleHeaderClick={handleHeaderClick}/>
                    ))}
                </div>

            </div>
        </Page>
    );
};

export default AdminExperienceSet;
