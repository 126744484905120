import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from "react-router";
import Page from '../layout/Page';
import ProgressRing from '../guest/ProgressRing';

import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';


interface Props { }

const ClosingPage: FunctionComponent<Props> = () => {

    let pushed_variables: any = useHistory();
    let progress = 50;

    const [budget, setBudget] = useState(0);
    const [currentBudget, setCurrentBudget] = useState(0);

   useEffect(() => {
         setBudget(pushed_variables?.location.state.budget);
         setCurrentBudget(pushed_variables.location.state.currentBudget);
   });
    

    return (
<Page>
   <div className="closing">
      <div className="closing__content">
         <ProgressRing radius={75} stroke={4} progress={currentBudget && budget ? (currentBudget / budget) * 100 : 0} budget={budget} currentBudget={currentBudget}/>
         <h2 className="closing__header">Einde van de Armoede Experience</h2>
         <p>Het is weer het einde van de week.. Je bent deze week doorgekomen. Meerdere dilemma&rsquo;s hebben zich voorgedaan deze week en misschien ben je tevreden of heb je spijt van de gemaakte keuzes.</p>
         <p>&nbsp;</p>
         <p><strong>Stress en Armoede</strong></p>
         <p>Uit onderzoek is gebleken dat er bij mensen die langdurig stress ervaren een <u>daling in het IQ</u> te zien is. Het negatieve effect van armoede op het <u>cognitief functioneren</u>, kan vergeleken worden met een daling in het IQ van 13 punten (Jungmann &amp; Madern, 2017).</p>
         <p>De daling in het IQ valt te verklaren vanuit het idee dat <u>armoede gerelateerde gedachtes mentale bronnen benutten</u>, waardoor er minder <u>capaciteit is voor andere taken.</u> Dat wil zeggen dat mensen die langdurig stress ervaren door armoede vaker keuzes maken die voor andere lastig te begrijpen zijn. De <u>daling in het IQ heeft daarnaast effect op het korte en lange termijn redeneren</u>. Het kan namelijk zijn dat mensen die langdurige stress ervaren keuzes maken en oplossingen bedenken die goed zijn voor de korte termijn, maar hierbij niet nadenken over de gevolgen wat betreft het lange termijn (Jungmann &amp; Madern, 2017).</p>
         <p>&nbsp;</p>
         <p><strong>Psychische en lichamelijke gezondheid en Armoede</strong></p>
         <p><u>De connectie tussen psychische problemen en armoede is vaak wederkerig</u>: Mensen met psychische problemen lopen een groter risico om schulden te krijgen en mensen in armoede/ met schulden hebben meer kans in het ontwikkelen van psychische problemen (Mullainathan &amp; Shafir, 2013).</p>
         <p>Armoede kan ook van invloed zijn op de <u>lichamelijke gezondheid</u> van de mens. Bijvoorbeeld doordat mensen (gedwongen) besparen op niet vergoede zorgkosten, zoals medicatie, steunzolen, fysiotherapeut, chiropractor, etc. Lichamelijke klachten worden hierdoor niet verholpen en in sommige gevallen worden de klachten nog erger, waardoor de kosten op lange termijn hoger zullen worden (Mullainathan &amp; Shafir, 2013).</p>
         <p><strong>&nbsp;</strong></p>
         <p><strong>Voedsel, lichamelijke beweging en Armoede</strong></p>
         <p>Gezond en voedzaam eten is <u>relatief duurder</u> dan ongezonder en minder voedzaam eten. Volgens ervaringsdeskundigen zullen mensen die minder te besteden hebben aan boodschappen daarom vaker gedwongen moet kiezen voor de ongezondere en minder voedzame optie (ervaringsdeskundigen, 2020). Wanneer volwassenen of kinderen een sport willen beoefenen komen hier niet alleen contributiekosten, kosten voor kleding en attributen bij, maar ook het rijden naar uitwedstrijden, teamuitjes en het wassen van sportkleding. Voor mensen die weinig te besteden hebben is dit vaak geen optie. Helaas resulteert dit in <u>minder sociale contacten</u> en <u>minder lichamelijke beweging</u> (ervaringsdeskundigen, 2020).</p>

         <Link className="button button--primary" to="/">Afsluiten en naar startpagina <ArrowRight className="closing__arrow-right-icon" /></Link>
      </div>
   </div>
</Page>
    );
};

export default ClosingPage;
