import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import Page from '../layout/Page';
import {useHistory} from "react-router-dom";

import ControlBox from '../guest/ControlBox';
import Dilemma from '../guest/Dilemma';

import DilemmaRepository from "./../../repos/DilemmaRepository";
import ExperienceSetRepository from "./../../repos/ExperienceSetRepository";

import DilemmaModel from "../../models/Dilemma";
import DilemmaOptionModel from "../../models/DilemmaOption";
import ExperienceSetModel from "../../models/ExperienceSet";

import Home from "./Home";


interface Props {
}


const Experience: FunctionComponent<Props> = () => {


    const [dilemmas, setDilemmas] = useState<DilemmaModel[]>([]);
    const dilemmaRepository = new DilemmaRepository();
    const experienceSetRepository = new ExperienceSetRepository();


    const [currentDilemma, setCurrentDilemma] = useState<DilemmaModel>();
    const [currentDilemmaIndex, setCurrentDilemmaIndex] = useState<number>(0);
    const [budget, setBudget] = useState<number>(80);
    const [currentBudget, setCurrentBudget] = useState<number>(budget);
    const [error, setError] = useState<string>();
    const [stoppedProgress, setStoppedProgress] = useState<boolean>(false);

    let history = useHistory();
    const subDilemmaRef = useRef(null)


    useEffect(() => {

        let experienceSet = 1;

        experienceSetRepository.find(experienceSet).then((experience) => {
            experience = experience as ExperienceSetModel
            let dilemmas = experience.dilemmas;
            setDilemmas(experience.dilemmas);
            setBudget(experience.budget);
            setCurrentBudget(experience.budget);
        });
        
    }, []);


    const executeScroll = () => scrollToRef(subDilemmaRef)

    const scrollToRef = (ref: any) => {
        window.scrollTo({left: 0, top: ref.current.firstElementChild.clientHeight + 40, behavior: 'smooth'})
    }

    const stopProgress = () => {
        setStoppedProgress(true);
    }

    const handleControls = (movement: number, currentBudgetLocal ?: number) => {

        if (currentDilemmaIndex + movement >= 0 && currentDilemmaIndex + movement < dilemmas.length) {
            setCurrentDilemmaIndex(currentDilemmaIndex + movement);

        } else if (currentDilemmaIndex + 1 == dilemmas.length && checkIfAllSelected()) {
            let data = {
                budget: budget,
                currentBudget: currentBudgetLocal ?? currentBudget
            }
            history.push({pathname: "/end", state: data});
        }

    }

    const checkIfAllSelected = () => {
        for (let i = 0; i < dilemmas.length; i++) {
            const options: DilemmaOptionModel[] = dilemmas[i].options;

            const optionIndex = options.findIndex(o => o.selected);
            const subDilemmaIndex = dilemmas[i]?.sub_dilemma?.options.findIndex(o => o.selected) ?? 0;

            if (optionIndex === -1 || subDilemmaIndex === -1) {
                setError("Niks geselecteerd bij dilemma: " + (i + 1));
                return false;
            }
        }

        return true;
    }

    const handleOptionClick = (dilemma: DilemmaModel, option: any, indexDilemma: number, indexOption: number, isSubDilemma: boolean) => {

        let budgetDifference: number = 0;
        let removeSubdilemma = false;
        let selectedOption = false;


        for (let i = 0; i < dilemma.options.length; i++) {

            //Dilemma is geselecteerd
            if (i == indexOption && dilemma.options[i].selected != true) {

                budgetDifference -= option.costs;
                dilemma.options[i].selected = true;
                selectedOption = true;
                

                //is hiervoor geselecteerd?
            } else if ((dilemma.options[i].selected == true) || (i == indexOption && dilemma.options[i].selected == true)) {


                if (dilemma.sub_dilemma != undefined) {

                    for (let j = 0; j < dilemma.sub_dilemma.options.length; j++) {

                        if (dilemma.sub_dilemma.options[j].selected) {
                            budgetDifference += dilemma.sub_dilemma.options[j].costs;
                        }

                        dilemma.sub_dilemma.options[j].selected = false;
                    }

                    dilemma.sub_dilemma = undefined;
                    removeSubdilemma = true;
                }

                budgetDifference += dilemma.options[i].costs;
                dilemma.options[i].selected = false;

            } else {
                dilemma.options[i].selected = false;
            }

        }

        if (option.sub_dilemma && !removeSubdilemma) {
            dilemmas[indexDilemma].sub_dilemma = option.sub_dilemma;
            setTimeout(executeScroll, 500);

        }

        setCurrentBudget(currentBudget + budgetDifference);


        if(!option.sub_dilemma && selectedOption) {
            setTimeout(() => { handleControls(+1, currentBudget + budgetDifference); }, 1000);
        }

        setDilemmas([...dilemmas]);

    }

    const hasSelectedDilemma = (): boolean => {
        return dilemmas[currentDilemmaIndex] !== undefined
            && dilemmas[currentDilemmaIndex].sub_dilemma !== undefined
    };

    const continueExperience = () => {
        setStoppedProgress(false);
    }


    if(stoppedProgress) {
        return <Home onContinueExperience={continueExperience}/>
    } else {
        return (
            <Page>
                <div className="page-content experience">
                    <ControlBox onSelect={handleControls} onStopProgress={stopProgress} dilemmas={dilemmas} currentDilemmaIndex={currentDilemmaIndex}
                                error={error}/>
                    {
                        dilemmas?.length > 0 &&
                        <div className="experience__boxes">
    
                            <div className="experience__box">
                                <Dilemma dilemma={dilemmas[currentDilemmaIndex]} index={currentDilemmaIndex}
                                            onOptionSelect={handleOptionClick} budget={budget} currentBudget={currentBudget}
                                            isSubDilemma={false}/>
                            </div>
    
                            <div className="experience__box" ref={subDilemmaRef}>
                                {hasSelectedDilemma() &&
                                <Dilemma dilemma={dilemmas[currentDilemmaIndex].sub_dilemma ?? {} as DilemmaModel}
                                            index={currentDilemmaIndex} onOptionSelect={handleOptionClick} budget={budget}
                                            currentBudget={currentBudget} isSubDilemma={true}/>
                                }
                            </div>
                        </div>
                    }
    
                </div>
            </Page>
        );
    }


  

};

export default Experience;
