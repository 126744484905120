import React, { FunctionComponent } from 'react';
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/layout/Footer";

import AuthContextProvider from "./contexts/AuthContext";
import User from "./models/User";
import Content from './components/layout/Content';

interface ProviderProps {
    user?: User;
    children: any;
}

const Providers: FunctionComponent<ProviderProps> = ({ user, children }) => {
    return (
        <AuthContextProvider user={user}>
                { children }
        </AuthContextProvider>
    );
};

interface Props {
    user?: User
}

const App: FunctionComponent<Props> = ({ user }) => {

    return (
        <BrowserRouter>
             <Providers user={user}>
                <Content />
                <Footer/>
             </Providers>
        </BrowserRouter>
    );
};

export default App;
