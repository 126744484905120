import React, {FunctionComponent, useContext} from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";

import Logo from '../../assets/images/logo/HAN Logo.svg';


interface OwnProps {

}

type Props = OwnProps;

const NavBar: FunctionComponent<Props> = (props) => {

    const {logout, isAuthenticated} = useContext(AuthContext);
    const history = useHistory();

    const logoutUser = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        await logout();
        history.push("/");
    }

    return (
        <nav>
            <div className="nav-content">
                <div className="nav-main-menu">
                    <Link to={'/'}>
                        <img src={Logo} className="logo" alt="logo"/>
                    </Link>
                </div>
                {
                    isAuthenticated() ?
                        <div className="nav-auth-menu">
                            <NavLink to={'/admin'}>Admin</NavLink>
                            <Link to={'/'} onClick={logoutUser}>Uitloggen</Link>
                        </div>
                        :
                        <div className="nav-auth-menu">
                        </div>
                }
            </div>
        </nav>
    );
};

export default NavBar;
